import SeoComponent from 'src/components/Seo'
import WelcomeModal from './components/WelcomeModal'
import useWorkspace from './hooks/useWorkspace'
import s from './styles.module.scss'
import ActionsPanel from './components/ActionsPanel'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import DashboardMetrics from './components/DashboardMetrics'
import MembersList from './components/MembersList'
import AddMembersModal from './components/AddMembersModal'
import ActionModal from 'src/components/ActionModalV2'
import { actionModalValues } from './utils'
import FilterDrawer from './components/FilterDrawer'
import SearchAndFilterPanel from './components/SearchAndFilterPanel'
import MemberDetailsModal from './components/MemberDetailsModal'
import Toast from 'src/components/Toast'
import { isMobile } from 'src/utils/mediaQueries'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import BankDetailsContent from './components/BankDetailsContent'
import { ListingsSkeletalCards } from 'src/components/SkeletalCards'

const RepresentativeWorkspace = () => {
    const {
        showWelcomeModal,
        closeWelcomeModal,
        showAddMembersModal,
        openAddMembersModal,
        closeAddMembersModal,
        actionModalOpen,
        modalAction,
        actionModalHandler,
        members,
        handleHoldNewMember,
        pageAction,
        handleChangePageAction,
        membersWithDuePayment,
        storeActionAndOpenModal,
        closeActionModal,
        filterDrawerVisible,
        handleFilterDrawerOpen,
        onFilterDrawerClose,
        membersListFiltered,
        applyFilter,
        resetFilter,
        showMemberDetailsModal,
        openMemberDetailsModal,
        closeMemberDetailsModal,
        secondaryActionModalHandler,
        memberDetails,
        userInfo,
        clusterSummary,
        toastError,
        errorMessage,
        actionLoading,
        totalMemberPages,
        filterStatus,
        setFilterStatus,
        profileSystemSizes,
        currentPage,
        updatePage,
        exportMembers,
        bankDetailsModal,
        successAlert,
        handlePaymentConfirmation,
        totalDuePaymentAmount,
        clusterMembersFetching,
    } = useWorkspace()
    const {
        actionModalTitle,
        actionModalHeaderText,
        actionModalSubtitle,
        actionModalActionType,
        actionModalPrimaryBtnText,
        actionModalFooterLeftContent,
        actionModalSecondaryBtnText,
    } = actionModalValues(modalAction, memberDetails, membersWithDuePayment)

    return (
        <>
            {/* modals */}
            <WelcomeModal
                showModal={showWelcomeModal}
                onCancel={closeWelcomeModal}
                firstName={userInfo?.firstName ?? ''}
                companyLogo={userInfo?.clusterLogo ?? ''}
            />

            <AddMembersModal
                showModal={showAddMembersModal}
                onCancel={closeAddMembersModal}
                handleHoldNewMember={handleHoldNewMember}
            />

            <ActionModal
                actionModalOpen={actionModalOpen}
                title={actionModalTitle}
                headerText={actionModalHeaderText}
                subTitle={actionModalSubtitle}
                actionType={actionModalActionType}
                primaryBtnText={actionModalPrimaryBtnText}
                handlePrimaryBtnClick={actionModalHandler}
                footerLeftContent={actionModalFooterLeftContent}
                secondaryBtnText={actionModalSecondaryBtnText}
                handleSecondaryBtnClick={secondaryActionModalHandler}
                closable
                noBackLink
                onCancel={closeActionModal}
                primaryBtnLoading={actionLoading}
                kycSecondaryButtonStopLoading={true}
            />

            <SecondaryModal
                showModal={bankDetailsModal}
                title="Cluster member payment"
                showFooter
                modalWidth={'475px'}
                primaryBtnText="Transfer done"
                handlePrimaryBtnClick={() => {
                    handlePaymentConfirmation()
                }}
                v2
                content={
                    <BankDetailsContent
                        successAlert={successAlert}
                        totalDuePaymentAmount={totalDuePaymentAmount}
                    />
                }
            />

            {toastError && (
                <Toast
                    messageType="error-secondary"
                    message={errorMessage}
                    closable={true}
                />
            )}

            {/* drawers */}
            <FilterDrawer
                visible={filterDrawerVisible}
                onDrawerClose={onFilterDrawerClose}
                applyFilter={applyFilter}
                resetFilter={resetFilter}
                setFilterStatus={setFilterStatus}
                profileSystemSizes={profileSystemSizes}
                filterStatus={filterStatus}
            />
            <MemberDetailsModal
                showModal={showMemberDetailsModal}
                onCancel={closeMemberDetailsModal}
                memberDetails={memberDetails}
            />

            {/* page */}
            <SeoComponent title="Representative Workspace | SunFi | Simplifying and Scaling Clean Energy" />
            <div className={s.page}>
                <div className={s.main}>
                    <div className={s.mainContent}>
                        {!isMobile && <VerticalSpacer top={56} />}
                        <ActionsPanel
                            openAddMembersModal={openAddMembersModal}
                            pageAction={pageAction}
                            handleChangePageAction={handleChangePageAction}
                            membersWithDuePayment={membersWithDuePayment}
                            storeActionAndOpenModal={storeActionAndOpenModal}
                            firstName={userInfo?.firstName ?? ''}
                        />
                        <VerticalSpacer top={24} />
                        {pageAction === 'view-dashboard' && (
                            <DashboardMetrics clusterSummary={clusterSummary} />
                        )}
                        <VerticalSpacer top={24} />

                        {pageAction === 'view-dashboard' &&
                            (members?.length > 0 || membersListFiltered) && (
                                <>
                                    <SearchAndFilterPanel
                                        handleFilterDrawerOpen={
                                            handleFilterDrawerOpen
                                        }
                                        membersListFiltered={
                                            membersListFiltered
                                        }
                                        resetFilter={resetFilter}
                                        handleExport={exportMembers}
                                        applyFilter={applyFilter}
                                        setFilterStatus={setFilterStatus}
                                        filterStatus={filterStatus}
                                    />
                                    <VerticalSpacer top={16} />
                                </>
                            )}
                        {clusterMembersFetching ? (
                            <ListingsSkeletalCards total={0} columnCount={5} />
                        ) : (
                            <MembersList
                                pageAction={pageAction}
                                members={members}
                                membersWithDuePayment={membersWithDuePayment}
                                openMemberDetailsModal={openMemberDetailsModal}
                                storeActionAndOpenModal={
                                    storeActionAndOpenModal
                                }
                                totalPages={totalMemberPages}
                                currentPage={currentPage}
                                updatePage={updatePage}
                                membersListFiltered={membersListFiltered}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default RepresentativeWorkspace
