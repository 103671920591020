import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import {
    addClusterMember,
    exportMembers,
    getAllClusterRepMember,
    removeClusterMember,
    resendSystemSelectionLink,
    retrieveClusterSummary,
} from 'src/api/representative/members'
import { decodeUserInfo } from 'src/utils/auth'
import { errorHandler } from 'src/utils/errorHandler'
import { useDispatch, useSelector } from 'react-redux'
import {
    updateClusterRepMembersFilter,
    updatedClusterRepMembersFilters,
} from 'src/redux/reducers/representative/members/listing'
import {
    convertQueryParamsToObject,
    numberWithCommas,
} from 'src/utils/formatting'
import { getProfilePackageCapacity } from 'src/api/admin/profiles/details'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { format } from 'date-fns'

const useWorkspace = () => {
    const { successAlert } = useCustomToast()
    const [showWelcomeModal, setShowWelcomeModal] = useState(false)
    const [showAddMembersModal, setShowAddMembersModal] = useState(false)
    const [actionModalOpen, setActionModalOpen] = useState(false)
    const [modalAction, setModalAction] = useState('') // notify-member-warning | notify-member-success | make-group-payment-confirmation-request
    const [members, setMembers] = useState([])
    const [memberDetailsPayload, setMemberDetailsPayload] = useState({})
    const [pageAction, setPageAction] = useState('view-dashboard') // view-dashboard | make-payment
    const [membersWithDuePayment, setMembersWithDuePayment] = useState([])
    const [filterDrawerVisible, setFilterDrawerVisible] = useState(false)
    const [membersListFiltered, setMembersListFiltered] = useState(false)
    const [showMemberDetailsModal, setShowMemberDetailsModal] = useState(false)
    const [memberDetails, setMemberDetails] = useState({})
    const [clusterSummary, setClusterSummary] = useState({})
    const [toastError, setToastError] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [totalMemberPages, setMembersTotalPages] = useState(0)
    const defaultQueries = useSelector(updatedClusterRepMembersFilters)
    const [profileSystemSizes, setProfileSystemSizes] = useState([])
    const [bankDetailsModal, setBankDetailsModal] = useState(false)
    const dispatch = useDispatch()
    const initialState = {
        member_status:
            defaultQueries?.member_status?.length > 0
                ? [defaultQueries.member_status].map(item => item)
                : [],
        due_payment: defaultQueries?.due_payment ?? false,
        system_size: defaultQueries?.system_size ?? '',
        payment_type: defaultQueries?.payment_type ?? '',
        payment_amount: defaultQueries?.payment_amount ?? '',
        page: Number(defaultQueries?.page) ?? 1,
        name: defaultQueries?.name ?? '',
    }

    const [filterStatus, setFilterStatus] = useState(initialState)

    const userInfo = decodeUserInfo()

    const closeWelcomeModal = () => {
        setShowWelcomeModal(false)
    }

    const openAddMembersModal = () => {
        setShowAddMembersModal(true)
    }

    const closeAddMembersModal = () => {
        setShowAddMembersModal(false)
    }

    const storeActionAndOpenModal = (action, member) => {
        setMemberDetails(member)
        setModalAction(action)
        setActionModalOpen(true)
    }

    const handleHoldNewMember = payload => {
        setMemberDetailsPayload(payload)
        storeActionAndOpenModal('notify-member-warning')
    }

    const handleChangePageAction = action => {
        setPageAction(action)
    }

    const handlePaymentConfirmation = () => {
        setBankDetailsModal(false)
        setModalAction('make-payment-confirmation')
        setActionModalOpen(true)
    }

    useEffect(() => {
        if (userInfo.isFirstTimeLogin === 'true') {
            setShowWelcomeModal(true)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setFilterStatus(() =>
            pageAction === 'make-payment'
                ? { due_payment: true }
                : { ...initialState, due_payment: false },
        )

        // eslint-disable-next-line
    }, [pageAction])

    const getDueAmount = () => {
        if (pageAction === 'make-payment') {
            return membersWithDuePayment?.reduce(
                (total, item) =>
                    numberWithCommas(
                        total +
                            parseFloat(
                                item.monthly_payment.replaceAll(',', ''),
                            ),
                    ),
                0,
            )
        } else {
            return memberDetails?.monthly_payment
        }
    }

    const queryKey = [
        'clusterRepMembers',
        filterStatus?.member_status,
        filterStatus?.payment_type,
        filterStatus?.system_size,
        filterStatus?.payment_amount,
        filterStatus?.due_payment,
        filterStatus?.page,
        filterStatus?.name,
    ]

    const {
        refetch: refetchClusterMembers,
        isLoading: clusterMembersFetching,
    } = useQuery(
        queryKey,
        () =>
            getAllClusterRepMember(
                filterStatus?.member_status?.map(item => item).join(',') || [],
                filterStatus?.payment_type || '',
                filterStatus?.system_size || '',
                filterStatus?.payment_amount || '',
                filterStatus?.due_payment || '',
                filterStatus?.page || 1,
                filterStatus?.name || '',
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: res => {
                const queries = res?.config?.url
                    .split('?')[1]
                    ?.replaceAll('=', ':')
                    ?.split('&')
                dispatch(
                    updateClusterRepMembersFilter(
                        convertQueryParamsToObject([...queries]),
                    ),
                )

                if (pageAction === 'make-payment') {
                    setMembersWithDuePayment(res?.data?.data)
                } else {
                    setMembers(res?.data?.data)
                }
                setMembersTotalPages(res?.data?.total_pages)
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    useQuery(
        'profilePackageRatedCapacity',
        () => getProfilePackageCapacity(userInfo?.clusterProfileId),
        {
            enabled: true,
            onSuccess: res => {
                setProfileSystemSizes(res?.data?.data)
            },
            onError: err => {
                setToastError(true)
                setErrorMessage(errorHandler(err?.response?.data))
            },
        },
    )

    const addNewClusterMember = useMutation({
        mutationFn: () => addClusterMember(memberDetailsPayload),
        onSuccess: () => {
            setModalAction('notify-member-success')
            refetchClusterMembers()
        },
        onError: error => {
            setToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    useQuery('clusterSummary', () => retrieveClusterSummary(), {
        enabled: true,
        retry: false,
        onSuccess: data => {
            setClusterSummary(data?.data?.data)
        },
        onError: error => {
            setToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    const { refetch: exportMembersList } = useQuery(
        'download-csv',
        () =>
            exportMembers(
                filterStatus?.member_status?.map(item => item).join(',') || '',
                filterStatus?.payment_type || '',
                filterStatus?.system_size || '',
                filterStatus?.payment_amount || '',
                filterStatus?.due_payment || '',
                filterStatus?.page || 1,
                filterStatus?.name || '',
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const clusterName = userInfo?.clusterName.replaceAll(' ', '_')
                const date = format(new Date(), 'yyyy_MM_dd_HH_mm')
                const outputFilename = `${clusterName}_${date}.xls`
                const url = URL.createObjectURL(new Blob([data.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', outputFilename)
                document.body.appendChild(link)
                link.click()
                link.remove()
                window.URL.revokeObjectURL(url)
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const resendSystemSelectionMutation = useMutation({
        mutationKey: ['resendSystemSelection'],
        mutationFn: () => resendSystemSelectionLink(memberDetails.id),
        onSuccess: () => {
            setModalAction('resend-system-selection-link-success')
        },
        onError: error => {
            setToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    const removeClusterMemberMutation = useMutation({
        mutationKey: ['removeClusterMember'],
        mutationFn: () => removeClusterMember(memberDetails.id),
        onSuccess: () => {
            setModalAction('remove-member-confirmation-success')
            refetchClusterMembers()
        },
        onError: error => {
            setToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    useEffect(() => {
        refetchClusterMembers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterStatus])

    const actionModalHandler = () => {
        switch (modalAction) {
            case 'notify-member-warning': {
                addNewClusterMember.mutate()
                break
            }
            case 'notify-member-success': {
                setActionModalOpen(false)
                setModalAction('')
                break
            }
            case 'make-group-payment-confirmation-request':
                setActionModalOpen(false)
                setBankDetailsModal(true)
                break
            case 'resend-system-selection-link':
                resendSystemSelectionMutation.mutate()
                break
            case 'resend-system-selection-link-success':
                setActionModalOpen(false)
                setModalAction('')
                break
            case 'make-single-payment-confirmation-request':
                setActionModalOpen(false)
                setBankDetailsModal(true)
                break
            case 'make-payment-confirmation':
                pageAction !== 'view-dashboard' &&
                    handleChangePageAction('view-dashboard')
                setActionModalOpen(false)
                setModalAction('')
                break
            case 'remove-member-confirmation-request':
                removeClusterMemberMutation.mutate()
                break
            case 'remove-member-confirmation-success':
                setActionModalOpen(false)
                setModalAction('')
                break
        }
    }

    const secondaryActionModalHandler = () => {
        switch (modalAction) {
            case 'remove-member-confirmation-request':
                setActionModalOpen(false)
                setModalAction('')
                break
        }
    }

    const closeActionModal = () => {
        setActionModalOpen(false)
    }

    const handleFilterDrawerOpen = () => {
        setFilterDrawerVisible(true)
    }

    const onFilterDrawerClose = () => {
        setFilterDrawerVisible(false)
    }

    const applyFilter = () => {
        setMembersListFiltered(true)
        setFilterDrawerVisible(false)
    }

    const resetFilter = () => {
        setMembersListFiltered(false)
        setFilterStatus({
            member_status: [],
            due_payment: false,
            system_size: '',
            payment_type: '',
            page: 1,
            name: '',
            payment_amount: '',
        })
        setFilterDrawerVisible(false)
    }
    const openMemberDetailsModal = member => {
        setMemberDetails(member)
        setShowMemberDetailsModal(true)
    }

    const closeMemberDetailsModal = () => {
        setShowMemberDetailsModal(false)
    }

    const updatePage = page => {
        setFilterStatus((...prev) => ({
            ...prev,
            page,
        }))
    }

    return {
        showWelcomeModal,
        closeWelcomeModal,
        showAddMembersModal,
        openAddMembersModal,
        closeAddMembersModal,
        actionModalOpen,
        modalAction,
        actionModalHandler,
        members,
        handleHoldNewMember,
        pageAction,
        handleChangePageAction,
        membersWithDuePayment,
        storeActionAndOpenModal,
        closeActionModal,
        filterDrawerVisible,
        handleFilterDrawerOpen,
        onFilterDrawerClose,
        membersListFiltered,
        applyFilter,
        resetFilter,
        showMemberDetailsModal,
        openMemberDetailsModal,
        closeMemberDetailsModal,
        secondaryActionModalHandler,
        memberDetails,
        userInfo,
        clusterSummary,
        toastError,
        errorMessage,
        actionLoading:
            addNewClusterMember.isLoading ||
            removeClusterMemberMutation.isLoading ||
            resendSystemSelectionMutation.isLoading,
        totalMemberPages,
        filterStatus,
        setFilterStatus,
        profileSystemSizes,
        currentPage: filterStatus.page,
        updatePage,
        exportMembers: exportMembersList,
        bankDetailsModal,
        successAlert,
        handlePaymentConfirmation,
        totalDuePaymentAmount: getDueAmount(),
        clusterMembersFetching,
    }
}

export default useWorkspace
