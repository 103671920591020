import PropTypes from 'prop-types'
import { ConsumerButton } from 'src/components/Button'
import { getGreetingTime } from 'src/utils/formatting'
import { ReactComponent as AddIcon } from 'src/assets/images/add-icon-no-stroke.svg'
import { ReactComponent as MoneysBlue } from 'src/assets/images/moneys-blue.svg'
import s from './styles.module.scss'
import BackNav from 'src/components/BackNav'
import { isMobile } from 'src/utils/mediaQueries'

const ActionsPanel = ({
    openAddMembersModal,
    pageAction,
    handleChangePageAction,
    membersWithDuePayment,
    storeActionAndOpenModal,
    firstName,
}) => {
    return (
        <div className={s.wrapper}>
            {pageAction === 'view-dashboard' && (
                <>
                    <h2 className={s.greeting}>
                        Good {getGreetingTime()}, {firstName}
                    </h2>
                    <div className={s.btns}>
                        <ConsumerButton
                            btnWidth={isMobile ? '100%' : '170px'}
                            btnHeight={'44px'}
                            btnBgColor={'#F9FCFF'}
                            btnTextColor={'#004AAD'}
                            type={'iconBtn'}
                            handleClick={() =>
                                handleChangePageAction('make-payment')
                            }
                            border={'0.5px solid #004AAD'}
                        >
                            <MoneysBlue className={s.moneysBlueIcon} /> Make due
                            payments
                        </ConsumerButton>
                        <ConsumerButton
                            btnWidth={isMobile ? '100%' : '146px'}
                            btnHeight={'44px'}
                            btnBgColor={'#004AAD'}
                            btnTextColor={'#FFFFFF'}
                            hoverNoFill
                            type={'iconBtn'}
                            handleClick={openAddMembersModal}
                        >
                            <AddIcon style={{ stroke: 'white' }} />
                            Add members
                        </ConsumerButton>
                    </div>
                </>
            )}
            {pageAction === 'make-payment' && (
                <>
                    <div className={s.leftContent}>
                        <div className={s.navAndBtn}>
                            <BackNav
                                v2
                                title="Go back"
                                onClick={() =>
                                    handleChangePageAction('view-dashboard')
                                }
                                marginBottom={10}
                            />
                            <ConsumerButton
                                btnWidth={'125px'}
                                btnHeight={'44px'}
                                btnBgColor={'#004AAD'}
                                btnTextColor={'#FFFFFF'}
                                disabled={membersWithDuePayment?.length === 0}
                                handleClick={() =>
                                    storeActionAndOpenModal(
                                        'make-group-payment-confirmation-request',
                                    )
                                }
                            >
                                Make payment
                            </ConsumerButton>
                        </div>
                        <h2>All your members with due payment</h2>
                        <p>
                            Below is a list of all your group members with due
                            payment.
                        </p>
                    </div>
                    <div className={s.desktopBtn}>
                        <ConsumerButton
                            btnWidth={'200px'}
                            btnHeight={'44px'}
                            btnBgColor={'#004AAD'}
                            btnTextColor={'#FFFFFF'}
                            disabled={membersWithDuePayment?.length === 0}
                            handleClick={() =>
                                storeActionAndOpenModal(
                                    'make-group-payment-confirmation-request',
                                )
                            }
                        >
                            Make payment for members
                        </ConsumerButton>
                    </div>
                </>
            )}
        </div>
    )
}

ActionsPanel.propTypes = {
    openAddMembersModal: PropTypes.func,
    pageAction: PropTypes.string,
    handleChangePageAction: PropTypes.func,
    membersWithDuePayment: PropTypes.array,
    storeActionAndOpenModal: PropTypes.func,
    firstName: PropTypes.string,
}

export default ActionsPanel
