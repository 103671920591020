const tableHeaders = [
    'name',
    'location',
    'system size',
    'monthly payment',
    'member status',
    '',
]

const tableHeadersForDuePayment = [
    'name',
    'location',
    'email',
    'monthly payment',
    'member status',
]

export const membersListValues = (
    pageAction,
    members,
    membersWithDuePayment,
    membersListFiltered,
) => {
    switch (pageAction) {
        case 'view-dashboard':
            return {
                list: members,
                tableHeaders: tableHeaders,
                isEmpty: members?.length === 0,
                emptyHeading: membersListFiltered
                    ? 'No results found. Try adjusting your filter or search'
                    : 'You have not added any member',
                emptyDescription: membersListFiltered
                    ? 'No matches found. Try broadening your filter or resetting it to see more options.'
                    : 'All members of this cluster will appear here, click on the add members button to get started.',
                rowClass: 'dashboardRow',
            }
        case 'make-payment':
            return {
                list: membersWithDuePayment,
                tableHeaders: tableHeadersForDuePayment,
                isEmpty: membersWithDuePayment?.length === 0,
                emptyHeading: 'There are no members with due payment',
                emptyDescription:
                    'Only members of your group members with due payment will appear here.',
                rowClass: 'paymentRow',
            }
        default:
            return {
                list: [],
                tableHeaders: [],
                isEmpty: false,
                emptyHeading: '',
                emptyDescription: '',
                rowClass: 'dashboardRow',
            }
    }
}

export const dropdownMenuOptions = [
    {
        title: 'View member details',
        action: 'view-member-details',
    },
    {
        title: 'Resend system selection link',
        action: 'resend-link',
    },
    {
        title: 'Make payment',
        action: 'make-payment',
    },
    {
        title: 'Remove this member',
        action: 'remove-member',
    },
]
