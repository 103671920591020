import PropTypes from 'prop-types'
import { Menu } from 'antd'
import { dropdownMenuOptions } from '../utils'

const DropdownMenu = ({
    openMemberDetailsModal,
    storeActionAndOpenModal,
    status,
    member,
}) => {
    return (
        <Menu>
            {dropdownMenuOptions?.map((item, i) =>
                ((item.action === 'resend-link' ||
                    item.action === 'remove-member') &&
                    (status !== 'Inactive' || member.due_payment)) ||
                (item.action === 'make-payment' &&
                    !member.due_payment) ? null : (
                    <Menu.Item
                        key={i}
                        onClick={() => {
                            if (item?.action === 'view-member-details') {
                                openMemberDetailsModal(member)
                            } else if (item?.action === 'resend-link') {
                                storeActionAndOpenModal(
                                    'resend-system-selection-link',
                                    member,
                                )
                            } else if (item?.action === 'make-payment') {
                                storeActionAndOpenModal(
                                    'make-single-payment-confirmation-request',
                                    member,
                                )
                            } else if (item?.action === 'remove-member') {
                                storeActionAndOpenModal(
                                    'remove-member-confirmation-request',
                                    member,
                                )
                            }
                        }}
                    >
                        <span
                            style={{
                                color:
                                    item?.action === 'remove-member'
                                        ? '#DA1E28'
                                        : '#233862',
                            }}
                        >
                            {item?.title}
                        </span>
                    </Menu.Item>
                ),
            )}
        </Menu>
    )
}

DropdownMenu.propTypes = {
    openMemberDetailsModal: PropTypes.func,
    storeActionAndOpenModal: PropTypes.func,
    status: PropTypes.string,
    member: PropTypes.object,
}

export default DropdownMenu
