import axios from '../../../config/axios'

export const getAllClusterRepMember = async (
    memberStatus,
    paymentType,
    systemSize,
    paymentAmount,
    duePayment,
    currentPage,
    name,
) => {
    const request = await axios.get(
        `/cluster-origination/reps/members?member_status=${memberStatus}&payment_type=${paymentType}&system_size=${systemSize}&payment_amount=${paymentAmount}&due_payment=${duePayment}&page=${currentPage}&name=${name}`,
    )
    return request
}

export const addClusterMember = async data => {
    const request = await axios.post(
        '/cluster-origination/reps/members/add',
        data,
    )
    return request
}

export const resendSystemSelectionLink = async id => {
    const request = await axios.post(
        `/cluster-origination/reps/members/${id}/selection-link`,
    )
    return request
}

export const removeClusterMember = async id => {
    const request = await axios.post(
        `/cluster-origination/reps/members/${id}/remove`,
    )
    return request
}

export const retrieveClusterSummary = async () => {
    const request = await axios.get(`/cluster-origination/reps/members/summary`)
    return request
}

export const exportMembers = async (
    memberStatus,
    paymentType,
    systemSize,
    paymentAmount,
    duePayment,
    currentPage,
) => {
    const request = await axios.get(
        `/cluster-origination/reps/members/export?member_status=${memberStatus}&payment_type=${paymentType}&system_size=${systemSize}&payment_amount=${paymentAmount}&due_payment=${duePayment}&page=${currentPage}&response_type=csv`,
    )
    return request
}
